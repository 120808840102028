import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityIntro, ContentEntityRichTitle, useEntity, SliceDivider, BenefitList, usePageEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { chunk } from 'lodash';
import { Page } from '@shapeable/rhodian-types';
const cls = classNames('slice-benefits');

// -------- Types -------->

export type SliceProductBenefitsProps = Classable & HasChildren & { 
  entity?: Slice;
};

export const SliceProductBenefitsDefaultProps: Omit<SliceProductBenefitsProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} 0;
    color: inherit;
    font-size: ${20/15}em;

    b, strong {
      color: inherit;
    }
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('serif')};
    font-weight: 300;
    font-size: 2.5em;
    line-height: 1.15em;
    text-align: center;
    padding: ${theme.UNIT(6)} 0;
  `,
});

const BenefitsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.UNIT(8)};
  `,
  tablet: css`
    justify-content: space-between;
    flex-direction: row;
  `,

});

const BenefitsColumnStyles = breakpoints({
  base: css`
    
  `,
});

const DividerStyles = breakpoints({
  base: css`
    
  `,
});

const BenefitsListStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Divider: styled(SliceDivider)`${DividerStyles}`,
    Title: styled(ContentEntityRichTitle)`${TitleStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,
    Benefits: styled.div`${BenefitsStyles}`,
      BenefitsColumn: styled.div`${BenefitsColumnStyles}`,
        BenefitsList: styled(BenefitList)`${BenefitsListStyles}`,
};

export const SliceProductBenefits: Shapeable.FC<SliceProductBenefitsProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const page = usePageEntity() as Page;
  const { product } = page;
  const { benefits = [] } = product;

  const benefitsColumns = chunk(benefits, Math.ceil(benefits.length / 2));

  return (
   <My.Container className={cls.name(className)}>
      <My.Title entity={entity} />
      <My.Divider entity={entity} color="light" />
      <My.Intro entity={entity} />
      <My.Divider entity={entity} color="light" />

      {
        !!benefits.length &&
        <My.Benefits>
          {
            benefitsColumns.map((benefits, i) => (
              <My.BenefitsColumn key={`benefits-column-${i}`}>
                <My.BenefitsList items={benefits} />
              </My.BenefitsColumn>
            ))
          }
        </My.Benefits>
      }
   </My.Container>
  )
};

SliceProductBenefits.defaultProps = SliceProductBenefitsDefaultProps;
SliceProductBenefits.cls = cls;