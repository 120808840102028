import { breakpoints, theme } from "@shapeable/theme";
import { css } from "styled-components";

export const HALO_SKY_BLUE = '#00AEEF';
export const HALO_BLUE_PURPLE = '#466DD2';
export const HALO_PURPLE = '#262262';

export const themeOverrides = {
  light: {
    color: {
      primary: HALO_SKY_BLUE,
  
      link: HALO_SKY_BLUE,
      linkHover: HALO_SKY_BLUE,
        
      invertedLinkHover: HALO_SKY_BLUE,
      invertedLink: '#FFF',
      invertedMenuHover: '#FFF',
      invertedLinkActive: 'light',
      invertedMenuLinkActive: 'light',
  
      invertedMenuActive: HALO_SKY_BLUE,
      invertedMenuHoverBackground: HALO_SKY_BLUE,
  
      midBrand: HALO_SKY_BLUE,
  
    },
  },
  font: {
    family: {
      serif: "Cormorant, georgia, serif",
      sans: 'Montserrat, sans-serif',
      heading: 'Cormorant, sans-serif',
      copy: 'Montserrat, sans-serif',
    },
  },
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`
    .shp--slice-layout-product-industries .shp--content h2,
    .shp--slice-layout-content-2 h2,
    .shp--slice-layout-content h2 {
      text-transform: uppercase;
    }

    .shp--slice-layout-block-bubbles .shp--block-image-content {
      text-align: center;
    }

    .shp--tabs__body {
      font-size: 1.2em;
    }

  `,
});