import { 
  createSliceLayout,
  COLOR_WARM_GREY,
  SlicePersonBioGrid,
  SlicePersonBioGridProps,
} from '@shapeable/ui';

import { SliceProductBenefits } from '../entities/slice-product-benefits';
import { ProductGrid } from '../entities/product-grid';
import { SliceProductIndustries } from '../entities/slice-product-industries';

export const SliceLayoutBenefits = createSliceLayout(
  SliceProductBenefits
);

export const SliceLayoutProductGrid = createSliceLayout(
  ProductGrid, {
    verticalPadding: 'small',
    boundary: 'content-bleed',
  }
);

export const SliceLayoutPeople = createSliceLayout<SlicePersonBioGridProps>(
  SlicePersonBioGrid, {
    verticalPadding: 'small',
    props: {
      transform: '',
    }
  }
); 


export const SliceLayoutProductIndustries = createSliceLayout(
  SliceProductIndustries, {
    verticalPadding: 'small',
    boundary: 'content',
    defaultBackgroundColor: COLOR_WARM_GREY,
  }
);